<template>
  <el-skeleton class="SubWebPage" :loading="fullscreenLoading" animated>
    <!-- <div class="divSemester">
      <el-select @change="onSelSemester" class="elSelSemester" v-model="selSemester" placeholder="请选择学期">
        <el-option v-for="item in optSemester" :key="item.semesterId" :label="item.name" :value="item.semesterId">
        </el-option>
      </el-select>
    </div> -->
    <div class="webContBox">
      <div class="content element-doc content pb-2" v-if="isHasData">
        <el-select @change="onClassChange" class="elSelClass" v-model="classId" placeholder="请选择班级" v-if="isClassNum">
          <el-option v-for="item in classOpt" :key="item.classId" :label="item.alias" :value="item.classId"></el-option>
        </el-select>
        <h2 v-else>{{ className }}</h2>
      </div>
      <el-row :gutter="20" v-if="isHasData">
        <el-col :xl="7" :lg="12">
          <el-card class="box-card">
            <div slot="header" class="card-header">
              <h3 class="card-title titleCont">
                <span class="spIcon"></span>
                行为规范
              </h3>
              <div class="card-tools">
                <ul class="nav nav-pills ml-auto">
                  <li class="nav-item">
                    <el-link type="info" class="elLinkBox" @click="onDetails(className, 1, studentMorality)">查看更多&gt;&gt;</el-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <div class="divBox">
                <div class="divListOne" v-for="(list, indexMorality) in studentMorality.slice(0, 5)" :key="indexMorality">
                  <div class="divLeft">
                    <div class="divTop">
                      <div class="divNo">
                        <span class="spNum VerticalMid" :class="list.rank == 1 ? 'spNum1' : list.rank == 2 ? 'spNum2' : list.rank == 3 ? 'spNum3' : ''">No.{{ list.rank }}</span>
                      </div>
                      <div class="divTitle">
                        {{ list.studentName }}
                      </div>
                    </div>
                    <div class="divBottom">
                      <span class="spLabel spAdd">加</span>
                      ：
                      <span class="spValue">{{ list.bonus }}</span>
                      <span class="spLabel spMinus">减</span>
                      ：
                      <span class="spValue">{{ list.deduction }}</span>
                    </div>
                  </div>
                  <div class="divRight">
                    <div class="divScore">
                      {{ list.totalScore }}
                    </div>
                    <div class="divFont VerticalMid">合计总分</div>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :xl="8" :lg="12">
          <el-card class="box-card">
            <div slot="header" class="card-header">
              <h3 class="card-title titleCont">
                <span class="spIcon"></span>
                班级活动
              </h3>
              <div class="card-tools">
                <ul class="nav nav-pills ml-auto">
                  <li class="nav-item">
                    <el-link type="info" class="elLinkBox" @click="onDetails(className, 2, studentActivity)">查看更多&gt;&gt;</el-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <div class="divBox">
                <div class="divListTwo" v-for="(list, indexActivity) in studentActivity.slice(0, 5)" :key="indexActivity">
                  <div class="divLeft">
                    <div class="divTop">
                      <div class="divNo">
                        <span class="spNum VerticalMid" :class="list.rank == 1 ? 'spNum1' : list.rank == 2 ? 'spNum2' : list.rank == 3 ? 'spNum3' : ''">No.{{ list.rank }}</span>
                      </div>
                      <div class="divTitle">
                        {{ list.studentName }}
                      </div>
                    </div>
                    <div class="divBottom">
                      <span class="spLabel spno1">校</span>
                      ：
                      <span class="spValue">{{ list.schoolQuantity }}</span>
                      <span class="spLabel spno2">部</span>
                      ：
                      <span class="spValue">{{ list.collegeQuantity }}</span>
                      <span class="spLabel spno3">班</span>
                      ：
                      <span class="spValue">{{ list.classQuantity }}</span>
                    </div>
                  </div>
                  <div class="divRight">
                    <div class="divScore">
                      {{ list.quantity }}
                    </div>
                    <div class="divFont VerticalMid">总参与</div>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :xl="9" :lg="24">
          <el-card class="box-card">
            <div slot="header" class="card-header">
              <h3 class="card-title titleCont">
                <span class="spIcon"></span>
                班级事务
              </h3>
            </div>
            <div class="card-body">
              <div class="divBox">
                <div class="divListThr">
                  <div class="divBottom">
                    <span class="spLabel">工作计划</span>
                    ：
                    <span class="spValue" :class="adviserHandBook.workPlan > 0 ? 'spYes' : 'spNo'">{{ adviserHandBook.workPlan > 0 ? '有' : '无' }}</span>
                  </div>
                  <div class="divBottom">
                    <span class="spLabel">活动计划</span>
                    ：
                    <span class="spValue" :class="adviserHandBook.activityPlan > 0 ? 'spYes' : 'spNo'">{{ adviserHandBook.activityPlan > 0 ? '有' : '无' }}</span>
                  </div>
                  <div class="divBottom">
                    <span class="spLabel">学期工作总结</span>
                    ：
                    <span class="spValue" :class="adviserHandBook.workSummary > 0 ? 'spYes' : 'spNo'">{{ adviserHandBook.workSummary > 0 ? '有' : '无' }}</span>
                  </div>
                  <div class="divBottom">
                    <span class="spLabel">主题班会</span>
                    ：
                    <span class="spValue spNumber">{{ adviserHandBook.classMeeting }}</span>
                    <span class="spValue">次</span>
                  </div>
                  <div class="divBottom">
                    <span class="spLabel">家校沟通</span>
                    ：
                    <span class="spValue spNumber">{{ adviserHandBook.parentActivityRecord }}</span>
                    <span class="spValue">次</span>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="80%" :before-close="handleClose">
      <div class="card-body">
        <el-table :data="dialogData" border class="elTableData" v-if="dialogType == 1">
          <el-table-column prop="rank" label="排名" class="elColumn" width="80"></el-table-column>
          <el-table-column prop="studentName" label="学生姓名" class="elColumn"></el-table-column>
          <el-table-column prop="bonus" label="总加分" class="elColumn"></el-table-column>
          <el-table-column prop="deduction" label="总减分" class="elColumn"></el-table-column>
          <el-table-column prop="totalScore" label="合计总分" class="elColumn"></el-table-column>
        </el-table>
        <el-table :data="dialogData" border class="elTableData" v-if="dialogType == 2">
          <el-table-column prop="rank" label="排名" class="elColumn" width="80"></el-table-column>
          <el-table-column prop="studentName" label="学生姓名" class="elColumn"></el-table-column>
          <el-table-column prop="schoolQuantity" label="校级活动数量" class="elColumn"></el-table-column>
          <el-table-column prop="collegeQuantity" label="部级活动数量" class="elColumn"></el-table-column>
          <el-table-column prop="quantity" label="合计总活动数量" class="elColumn"></el-table-column>
        </el-table>
        <el-table :data="dialogData" border class="elTableData" v-if="dialogType == 3">
          <el-table-column prop="className" label="班级名称" class="elColumn"></el-table-column>
          <el-table-column label="工作计划" class="elColumn">
            <template slot-scope="scope">
              <span :class="scope.row.workPlan > 0 ? 'color-success' : 'color-gray'">{{ scope.row.workPlan > 0 ? '有' : '无' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="活动计划" class="elColumn">
            <template slot-scope="scope">
              <span :class="scope.row.activityPlan > 0 ? 'color-success' : 'color-gray'">{{ scope.row.activityPlan > 0 ? '有' : '无' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="工作总结" class="elColumn">
            <template slot-scope="scope">
              <span :class="scope.row.workSummary > 0 ? 'color-success' : 'color-gray'">{{ scope.row.workSummary > 0 ? '有' : '无' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="classMeeting" label="主题班会" class="elColumn"></el-table-column>
          <el-table-column prop="parentActivityRecord" label="家校活动" class="elColumn"></el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </el-skeleton>
</template>

<script>
export default {
  components: {},
  computed: {
    isHasData() {
      if (this.studentActivity && this.studentMorality && this.adviserHandBook) {
        if (this.studentActivity.length > 0 && this.studentMorality.length > 0 && Object.keys(this.adviserHandBook).length > 0) {
          return true;
        }
      }
      return false;
    }
  },
  data() {
    return {
      // 加载动画
      dialogVisible: false,
      fullscreenLoading: false,
      adviserHandBook: null,
      studentActivity: null,
      studentMorality: null,
      itemData: [],
      classOpt: [],
      optSemester: [],
      selSemester: null,
      selSemesterName: null,
      classId: null,
      className: null,
      dialogTitle: '',
      dialogData: [],
      dialogType: 0,

      isClassNum: false
    };
  },
  created() {
    let that = this;
    onGetData(that);
  },

  methods: {
    /**
     * @description 班级下拉事件
     */
    onClassChange() {
      let that = this;
      let currentClass = that.classOpt.find((item) => item.classId == that.classId);
      that.className = currentClass.name;
      onGetData(that);
    },
    /**
     * @description 学期下拉事件
     */
    onSelSemester() {
      let that = this;
      onGetData(that);
    },
    /**
     * @description 关闭弹出框
     */
    handleClose() {
      let that = this;
      that.dialogVisible = false;
      that.dialogData = [];
    },
    /**
     * @description 打开查看更多
     * @param {Object} objName 年级名称
     * @param {Object} objType 类开
     * @param {Object} objData 数据
     */
    onDetails(objName, objType, objData) {
      let that = this;
      that.dialogType = objType;
      that.dialogData = objData;
      switch (objType) {
        case 1:
          that.dialogTitle = objName + ' | ' + '行为规范';
          break;
        case 2:
          that.dialogTitle = objName + ' | ' + '班级活动';
          break;
      }
      that.dialogVisible = true;
    }
  }
};
/**
 * @description 加载数据
 * @param {Object} that
 */
function onGetData(that) {
  that.fullscreenLoading = true;
  // 获取学期数据
  ajaxGet(that, '/api/admin/class/userclass', null, function (resClass) {
    ajaxGet(that, '/api/admin/semester', null, function (resSemester) {
      that.classOpt = resClass;

      if (!that.classId && resClass.length > 0) {
        //判断是否选择班级没有选择班级则默认选择第一个
        that.classId = resClass[0].classId;
        that.className = resClass[0].alias;
        if (resClass.length > 1) {
          that.isClassNum = true;
        }
      }
      that.optSemester = resSemester;
      if (!regNullArray(resSemester)) {
        // 判断是否已经选择学期
        if (regNull(that.selSemester)) {
          // 没有选择学期，则显示当前学期
          let temp = resSemester.find((item) => item.isCurrent == true);
          if (temp != undefined) {
            that.selSemester = temp.semesterId;
            that.selSemesterName = temp.name;
          }
        }
        // 判断是否有学期数据
        if (!regNull(that.selSemester) && !regNull(that.classId)) {
          ajaxGet(that, '/api/admin/home/studentanalysis?SemesterId=' + that.selSemester + '&ClassId=' + that.classId, null, function (resData) {
            that.fullscreenLoading = false;
            that.adviserHandBook = resData.adviserHandBook;
            that.studentActivity = resData.studentActivity;
            that.studentMorality = resData.studentMorality;

            that.itemData = [];
          });
        }
      } else {
        that.fullscreenLoading = false;
      }
    });
  });
}
</script>
<style scoped="scoped">
.SubWebPage >>> .divSemester {
  position: absolute;
  right: 15px;
  top: 0;
}

.SubWebPage >>> .elSelClass {
  min-width: 100px;
}

.SubWebPage >>> .elSelClass .el-input__inner,
.SubWebPage >>> .divSemester .el-input__inner {
  background-color: transparent !important;
  border-color: #f4f6f9;
  font-size: 24px;
  color: #303133;
}

.SubWebPage >>> .elSelClass .el-input__icon,
.SubWebPage >>> .divSemester .el-input__icon {
  font-size: 24px;
}
</style>
<style lang="less" scoped="scoped">
.divSemester {
}

.elLinkBox {
  font-weight: normal;
  margin-right: 5px;
}

.webContBox {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0px;
  }
}

.divBox {
  width: 100%;
  height: 400px;
  overflow-y: auto;
}

.divListThr {
  margin-bottom: 15px;
  padding-bottom: 15px;

  .divBottom {
    margin-bottom: 15px;
    .spLabel {
      font-size: 16px;
      padding: 1px 2px;
      border-radius: 4px;
      color: #909399;
      margin-left: 15px;
    }

    .spValue {
      font-size: 16px;
      padding: 1px 2px;
      margin-left: 10px;
    }

    .spYes {
      background-color: #f0f9eb;
      color: #67c23a;
    }

    .spNo {
      background-color: #f4f4f5;
      color: #909399;
    }

    .spNumber {
      background-color: #ecf5ff;
      color: #409eff;
    }
  }
}

.divListTwo {
  display: flex;
  border-bottom: 1px dotted #dddddd;
  margin-bottom: 15px;

  .spNum {
    font-size: 14px;
    border: 1px solid #f2f6fc;
    background-color: #f2f6fc;
    color: #909399;
    border-radius: 4px;
  }

  .spNum1 {
    color: #3e3e3e;
    background-color: #fce962;
    border: 1px solid #fce962;
  }

  .spNum2 {
    color: #3e3e3e;
    background-color: #c7d1db;
    border: 1px solid #c7d1db;
  }

  .spNum3 {
    color: #3e3e3e;
    background-color: #f1bd9c;
    border: 1px solid #f1bd9c;
  }

  &:last-child {
    border-bottom: none;
  }

  .divLeft {
    flex: 1;
  }

  .divTop {
    display: flex;

    .divNo {
      width: 60px;
      margin-right: 20px;
    }

    .divTitle {
      padding-top: 2px;
      color: #303133;
    }
  }

  .divBottom {
    margin-top: 5px;

    .spLabel {
      font-size: 14px;
      padding: 2px 4px;
      border-radius: 4px;
    }

    .spno1 {
      background-color: #ecf5ff;
      color: #409eff;
    }

    .spno2 {
      background-color: #fdf6ec;
      color: #e6a23c;
    }

    .spno3 {
      background-color: #f0f9eb;
      color: #67c23a;
    }

    .spValue {
      padding-right: 10px;
      color: #606266;
    }
  }

  .divRight {
    display: flex;

    .divScore {
      font-family: 'OswaldLight';
      font-size: 42px;
      text-align: right;
      padding-right: 5px;
      color: #1976d2;
    }

    .divFont {
      font-size: 12px;
      width: 20px;
      line-height: 13px;
      height: 45px;
      border-radius: 5px;
      border: 1px solid #d0d4db;
      text-align: center;
      margin-top: 10px;
      background-color: #f4f4f5;
      color: #909399;
    }
  }
}

.divListOne {
  display: flex;
  border-bottom: 1px dotted #dddddd;
  margin-bottom: 15px;

  .spNum {
    font-size: 14px;
    border: 1px solid #f2f6fc;
    background-color: #f2f6fc;
    color: #909399;
    border-radius: 4px;
  }

  .spNum1 {
    color: #3e3e3e;
    background-color: #fce962;
    border: 1px solid #fce962;
  }

  .spNum2 {
    color: #3e3e3e;
    background-color: #c7d1db;
    border: 1px solid #c7d1db;
  }

  .spNum3 {
    color: #3e3e3e;
    background-color: #f1bd9c;
    border: 1px solid #f1bd9c;
  }

  &:last-child {
    border-bottom: none;
  }

  .divLeft {
    flex: 1;
  }

  .divTop {
    display: flex;

    .divNo {
      width: 60px;
      margin-right: 20px;
    }

    .divTitle {
      padding-top: 2px;
      color: #303133;
    }
  }

  .divBottom {
    margin-top: 5px;

    .spLabel {
      font-size: 14px;
      padding: 2px 4px;
      border-radius: 4px;
    }

    .spAdd {
      background-color: #fef0f0;
      color: #f56c6c;
    }

    .spMinus {
      background-color: #f0f9eb;
      color: #67c23a;
    }

    .spValue {
      padding-right: 10px;
      color: #606266;
    }
  }

  .divRight {
    display: flex;

    .divScore {
      font-family: 'OswaldLight';
      font-size: 42px;
      text-align: right;
      padding-right: 5px;
      color: #1976d2;
    }

    .divFont {
      font-size: 12px;
      width: 20px;
      line-height: 13px;
      height: 55px;
      border-radius: 5px;
      border: 1px solid #d0d4db;
      text-align: center;
      margin-top: 2px;
      background-color: #f4f4f5;
      color: #909399;
    }
  }
}
</style>
